import React from 'react';

function Releases(props) {
  return (
    <div>
      
    </div>
  );
}

export default Releases;